import { useState } from "react";

// helpers
import { forgotPassword as forgotPasswordApi } from "../../helpers";

export default function useForgotPassword() {
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState("");
  const [error, setError] = useState("");
  const [passwordReset, setPasswordReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const forgotPassword = ({ emailAddress }: { emailAddress: string }) => {
    setLoading(true);
    const response = forgotPasswordApi({ emailAddress });
    response
      .then((response) => {
        setLoading(false);
        setResetPasswordSuccess(response.data.message);
        setPasswordReset(true);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          setError(error.response.data.message);
        } else if (error.request) {
          setError(error);
        } else {
          setError(error.message);
        }
        setPasswordReset(false);
      });
  };

  return [resetPasswordSuccess, error, passwordReset, forgotPassword,loading];
}
