import { APICore } from "./apiCore";

const api = new APICore();

const backendBaseUrl = process.env.REACT_APP_API_URL;
// account
function login(params: { username: string; password: string }) {
  const baseUrl = backendBaseUrl + "/authenticate";
  return api.createAuthOnly(`${baseUrl}`, params);
}

function logout() {
  const baseUrl = "/logout/";
  return api.create(`${baseUrl}`, {});
}

function signup(params: {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
  password: string;
}) {
  const baseUrl = backendBaseUrl + "/user/save";
  return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { emailAddress: string }) {
  const baseUrl = backendBaseUrl + "/user/requestPasswordResetLink";
  return api.create(`${baseUrl}`, params);
}

function setPassword(params: {
  password: string;
  userId: string;
  securityToken: string;
}) {
  const baseUrl =
    backendBaseUrl +
    "/user/resetNewPassword/" +
    params.userId +
    "/" +
    params.securityToken;
  return api.create(`${baseUrl}`, params);
}

// ORGANISATION APIS

export { login, logout, signup, forgotPassword, setPassword };
