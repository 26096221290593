import { BrowserRouter } from "react-router-dom";
import React from 'react'
// routes
import AllRoutes from ".";

const Routes = () => {
  return (
    <BrowserRouter>
      <AllRoutes />
    </BrowserRouter>
  );
};

export default Routes;
