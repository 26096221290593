import { APICore } from "./apiCore";

const api = new APICore();

const backendBaseUrl = process.env.REACT_APP_API_URL;

function createOrganisation(params: {
  name: string;
  location: string;
  phoneNumber: string;
  emailAddress: string;
  isActive: boolean;
  physicalAddress: string;
  employeeCount: string;
  website: string;
  industry: string;
  logo: string;
  owners: any;
}) {
  const baseUrl = backendBaseUrl + "/organisation/save";
  return api.createAuthenticated(`${baseUrl}`, params);
}

function createNewProject(params: {
  name: string;
  description: string;
  isActive: boolean;
  organisationId: string;
  channels: any;
  services: any;
}) {
  const baseUrl = backendBaseUrl + "/project/save";
  return api.createAuthenticated(`${baseUrl}`, params);
}

function getWallet(params: { organisationId: string }) {
  const baseUrl = backendBaseUrl + "/organisations/" + params.organisationId;
  return api.getAuthenticated(`${baseUrl}`);
}

function getServiceSystemConfigurationApi(params: { organisationId: string }) {
  const baseUrl =
    backendBaseUrl + "/organisations/" + params.organisationId + "/wallet";
  return api.getAuthenticated(`${baseUrl}`);
}

function getWalletStatement(params: { organisationId: string }) {
  const baseUrl =
    backendBaseUrl + "/organisations/" + params.organisationId + "/wallet";
  return api.getAuthenticated(`${baseUrl}`);
}

function getTransactionStatement(params: { organisationId: string, dates:any }) { 

  console.log(params?.dates?.searchKeyword);
  console.log("params?.dates?.searchKeyword");

  let baseUrl="";
  if(params.dates['searchKeyword'] ==="" || params.dates['searchKeyword'] ===null || params.dates['searchKeyword'] ===undefined){
     baseUrl =
    backendBaseUrl +
    "/organisations/" +
    params.organisationId +
    "/transactions/"+params.dates[0]?.getTime()+"/"+params.dates[1].getTime()+"/"+(params.dates?.currentPage)+"/"+params.dates?.selectedPageSize;
  }else{
     baseUrl =
    backendBaseUrl +
    "/organisations/" +
    params.organisationId +
    "/transactions/"+params.dates[0]?.getTime()+"/"+params.dates[1].getTime()+"/"+params.dates?.currentPage+"/"+params.dates?.selectedPageSize+"/"+params.dates['searchKeyword'];
  }
  
    ///organisations/{organisationId}/transactions/{startTimestamp}/{endTimestamp}/{currentPage}/{pageSize}
  return api.getAuthenticated(`${baseUrl}`);
}

function getUserOrganisationWithDetailsAPI(params: { userId: string }) {
  const baseUrl = backendBaseUrl + "/organisations/all/" + params.userId;
  return api.getAuthenticated(`${baseUrl}`);
}

function getUserOrganisationSingleWithDetailsApi(params: {
  organisation: any;
}) {
  const baseUrl = backendBaseUrl + "/organisations/" + params.organisation;
  return api.getAuthenticated(`${baseUrl}`);
}
 

function initiateWalletPayment( params: {params:any} ) {
  const baseUrl = backendBaseUrl + "/payments/initiate";
  return api.createAuthenticated(`${baseUrl}`, params);
}

function validateCheckoutPaymentDetails(params: {
  checkoutID: string;
}) {
  const baseUrl = backendBaseUrl + "/payments/verify/" + params.checkoutID;
  return api.getAuthenticated(`${baseUrl}`);
}


/*
  SERVICES
*/

function getServiceBulkApi(params: {
  organisationId: string;
}) {
  const baseUrl = backendBaseUrl + "/services/bulk/batches/all/" + params.organisationId;
  return api.getAuthenticated(`${baseUrl}`);
}

function getServiceBulkDatabasesApi(params: {
  organisationId: string;
}) {
  const baseUrl = backendBaseUrl + "/services/bulk/databases/all/" + params.organisationId;
  return api.getAuthenticated(`${baseUrl}`);
}

function uploadBulkDatabase(uploadBulkDatabase: any) {
  const baseUrl = backendBaseUrl + "/services/bulk/campaign/uploadDatabase";
  return api.createAuthenticated(`${baseUrl}`, uploadBulkDatabase.uploadBulkDatabase);
}

function createNewCampaignAPI(newCampaignPayload: any) {
  const baseUrl = backendBaseUrl + "/services/bulk/new/campaign";
  return api.createAuthenticated(`${baseUrl}`, newCampaignPayload.newCampaignPayload);
}

function publishCampaignNowAPI(publishPayload: any) {
  const baseUrl = backendBaseUrl + "/services/bulk/publish/campaign";
  return api.createAuthenticated(`${baseUrl}`, publishPayload.publishPayload);
}





export {
  createOrganisation,
  createNewProject,
  getWallet,
  getWalletStatement,
  getTransactionStatement,
  getUserOrganisationWithDetailsAPI,
  getUserOrganisationSingleWithDetailsApi,
  initiateWalletPayment,
  validateCheckoutPaymentDetails,
  getServiceBulkApi,
  uploadBulkDatabase,
  getServiceBulkDatabasesApi,
  createNewCampaignAPI,
  publishCampaignNowAPI,
  getServiceSystemConfigurationApi
};
