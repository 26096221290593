import { useState } from "react";

// helpers
import { signup as signupApi } from "../../helpers";
import { APICore, setAuthorization } from "../../helpers/api/apiCore";

export default function useSignup() {
  const api = new APICore();

  const [user, setUser] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const signup = ({
    firstName,
    lastName,
    phoneNumber,
    emailAddress,
    password,
  }: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    emailAddress: string;
    password: string;
  }) => {
    const response = signupApi({
      firstName,
      lastName,
      phoneNumber,
      emailAddress,
      password,
    });
    setLoading(true);
    response
      .then((response) => {
        setLoading(false);
        console.log(response);
        setUser(response.data);
        // api.setLoggedInUser(response.data);
        // setAuthorization(response.data!["token"]);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          setError(error.response.data.message);
        } else if (error.request) {
          setError(error);
        } else {
          setError(error.message);
        }
      });
  };

  return [user, error, signup, loading];
}
