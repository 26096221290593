// helpers
import { useState } from 'react';
import { login as loginApi } from '../../helpers';
import { APICore, setAuthorization } from '../../helpers/api/apiCore';

export default function useLogin() {
    const api = new APICore();

    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState();
    const [error, setError] = useState();
    const login = ({ username, password }: { username: string; password: string }): void => {
        setLoading(true);
        const response = loginApi({ username, password });
        response
            .then((response) => {
                setLoading(false)
                console.log(response.data.responseBody)
                setUser(response.data.responseBody);
                api.setLoggedInUser(response.data.responseBody);
                setAuthorization(response.data!['accessToken']);
            })
            .catch((error) => {
                setLoading(false)
                if (error.response) { 
                    setError(error.response.data.message);
                } else if (error.request) {
                    setError(error);
                } else {
                    setError(error.message);
                }
            });
    };

    return [ user, error, login,loading];
}
