import { useState } from "react";

// helpers
import { setPassword as setPasswordApi } from "../../helpers";

export default function useSetPassword(){
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState("");
  const [error, setError] = useState("");
  const [passwordReset, setPasswordReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const setPassword = ({
    password,
    userId,
    securityToken,
  }: {
    password: string;
    userId: string;
    securityToken: string;
  }) => {
    const response = setPasswordApi({ password, userId, securityToken });
    setLoading(true);
    response
      .then((response) => {
        setLoading(false);
        setResetPasswordSuccess(response.data.message);
        setPasswordReset(true);
      })
      .catch((error) => {
        setLoading(false);
        setPasswordReset(false);
        if (error.response) {
          setError(error.response.data.message);
        } else if (error.request) {
          setError(error);
        } else {
          setError(error.message);
        }
      });
  };

  return [resetPasswordSuccess, error, passwordReset, setPassword,loading];
}
