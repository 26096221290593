import React, { Suspense } from "react";
import { useRoutes } from "react-router-dom";

// components
import Root from "./Root";
import PrivateRoute from "./PrivateRoute";

// lazy load all the views
// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const SignUp = React.lazy(() => import("../pages/auth/SignUp"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const SetPassword = React.lazy(() => import("../pages/auth/SetPassword"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));

// home
const Home = React.lazy(() => import("../pages/Home"));

// landings
const App = React.lazy(() => import("../pages/landings/App"));
const Saas = React.lazy(() => import("../pages/landings/Saas/Saas"));
const Saas2 = React.lazy(() => import("../pages/landings/Saas/Saas2"));
const Startup = React.lazy(() => import("../pages/landings/Startup"));
const Software = React.lazy(() => import("../pages/landings/Software"));

const Agency = React.lazy(() => import("../pages/landings/Agency"));
const Coworking = React.lazy(() => import("../pages/landings/Coworking"));
const Crypto = React.lazy(() => import("../pages/landings/Crypto"));
const Marketing = React.lazy(() => import("../pages/landings/Marketing"));
const Portfolio = React.lazy(() => import("../pages/landings/Portfolio"));

// pages
const Dashboard = React.lazy(() => import("../pages/Dashboard"));
const Settings = React.lazy(() => import("../pages/other/account/Settings"));

//ORGANISATION
const Organisations = React.lazy(
  () => import("../pages/Dashboard/organisation/allOrganisations")
);
const OrganisationDetail = React.lazy(
  () => import("../pages/Dashboard/organisation/manageOrganisationDetail")
);
const Wallet = React.lazy(() => import("../pages/Dashboard/wallets/wallet"));
const WalletStatement = React.lazy(
  () => import("../pages/Dashboard/wallets/walletStatement")
);
const TransactionWidget = React.lazy(
  () => import("../pages/Dashboard/services/transactionStatement")
);
const SystemServiceConfigurationsWidget = React.lazy(
  () => import("../pages/Dashboard/services/SystemServiceConfigurations")
);

//Services
const ServiceDetail = React.lazy(
  () => import("../pages/Dashboard/services/serviceDetail")
);
const ServicePricingDetail = React.lazy(
  () => import("../pages/Dashboard/services/ServicePricingDetail")
);

const BulkServicePricingDetail = React.lazy(
  () => import("../pages/Dashboard/services/BulkServiceDetail")
);

// Pricing 

const PriceDetail = React.lazy(
  () => import("../pages/Dashboard/pricing/priceDetail")
);
const PriceDetailWidget = React.lazy(
  () => import("../pages/Dashboard/pricing/priceDetailWidget")
);

//Statements

//Projects
const Projects = React.lazy(
  () => import("../pages/Dashboard/projects/manageProjects")
);

//PAYMENTS
const PaymentResponsePage = React.lazy(
  () => import("../pages/Dashboard/payments/paymentResponsePage")
);

//CUSTOMER CHANNELS PAYMENTS
const CustomerChannelsPaymentInitializationPage = React.lazy(
  () => import("../pages/customer-channels/payments/InitializeCustomerPaymentPage")
);
const CustomerChannelPaymentResponsePage = React.lazy(
  () => import("../pages/customer-channels/payments/CustomerChannelPaymentResponsePageResponsePage")
);




const Blog = React.lazy(() => import("../pages/other/Blog"));
const BlogPost = React.lazy(() => import("../pages/other/BlogPost"));

const Company = React.lazy(() => import("../pages/other/Company"));
const Contact = React.lazy(() => import("../pages/other/Contact"));
const Career = React.lazy(() => import("../pages/other/Career"));
const Pricing = React.lazy(() => import("../pages/other/Pricing"));

const PortfolioGrid = React.lazy(
  () => import("../pages/other/portfolio/PortfolioGrid")
);
const PortfolioMasonry = React.lazy(
  () => import("../pages/other/portfolio/PortfolioMasonry")
);
const PortfolioItem = React.lazy(
  () => import("../pages/other/portfolio/PortfolioItem")
);

const HelpDesk = React.lazy(() => import("../pages/other/HelpDesk"));

// docs
const Introduction = React.lazy(() => import("../pages/docs/Introduction"));
const QuickStart = React.lazy(() => import("../pages/docs/QuickStart"));
const Customization = React.lazy(() => import("../pages/docs/Customization"));
const Routing = React.lazy(() => import("../pages/docs/Routing"));
const CodeSpliting = React.lazy(() => import("../pages/docs/CodeSpliting"));
const ChangeLog = React.lazy(() => import("../pages/docs/ChangeLog"));

const Colors = React.lazy(() => import("../pages/docs/Colors"));
const Typography = React.lazy(() => import("../pages/docs/Typography"));

const Bootstrap = React.lazy(() => import("../pages/docs/Bootstrap"));
const Custom = React.lazy(() => import("../pages/docs/Custom"));
const Plugins = React.lazy(() => import("../pages/docs/Plugins"));

const Navbars = React.lazy(() => import("../pages/docs/Navbars"));
const Heros = React.lazy(() => import("../pages/docs/Heros"));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
  component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
  <Suspense fallback={loading()}>
    <Component />
  </Suspense>
);

const AllRoutes = () => {
  return useRoutes([
    {
      // root route
      path: "/",
      element: <Root />,
    },
    {
      // public routes
      path: "/",
      children: [
        {
          path: "auth",
          children: [
            { path: "login", element: <LoadComponent component={Login} /> },
            { path: "signup", element: <LoadComponent component={SignUp} /> },
            {
              path: "forget-password",
              element: <LoadComponent component={ForgetPassword} />,
            },
            {
              path: "set-password/:userId/:securityToken",
              element: <LoadComponent component={SetPassword} />,
            },
            { path: "confirm", element: <LoadComponent component={Confirm} /> },
            { path: "logout", element: <LoadComponent component={Logout} /> },
          ],
        },
        {
          path: "docs",
          children: [
            {
              path: "introduction",
              element: <LoadComponent component={Introduction} />,
            },
            {
              path: "quick-start",
              element: <LoadComponent component={QuickStart} />,
            },
            {
              path: "customization",
              element: <LoadComponent component={Customization} />,
            },
            { path: "routing", element: <LoadComponent component={Routing} /> },
            {
              path: "code-spliting",
              element: <LoadComponent component={CodeSpliting} />,
            },
            {
              path: "change-log",
              element: <LoadComponent component={ChangeLog} />,
            },

            { path: "colors", element: <LoadComponent component={Colors} /> },
            {
              path: "typography",
              element: <LoadComponent component={Typography} />,
            },
            {
              path: "bootstrap",
              element: <LoadComponent component={Bootstrap} />,
            },
            { path: "custom", element: <LoadComponent component={Custom} /> },
            { path: "plugins", element: <LoadComponent component={Plugins} /> },
            { path: "navbars", element: <LoadComponent component={Navbars} /> },
            { path: "heros", element: <LoadComponent component={Heros} /> },
          ],
        },
        {
          path: "home",
          element: <LoadComponent component={Home} />,
        },
        {
          path: "payments",
          children: [
            {
              path: "verify",
              element: <LoadComponent component={PaymentResponsePage} />,
            },
          ],
        },
        
        {
          path: "customer-channel",
          children: [
            {
              path: "initialize-payment/:transactionRef",
              element: <LoadComponent component={CustomerChannelsPaymentInitializationPage} />,
            },
            {
              path: "result-payment/:transactionRef",
              element: <LoadComponent component={CustomerChannelPaymentResponsePage} />,
            },

            
          ],
        },
        
        {
          path: "landing",
          children: [
            { path: "app", element: <LoadComponent component={App} /> },
            { path: "saas", element: <LoadComponent component={Saas} /> },
            { path: "saas2", element: <LoadComponent component={Saas2} /> },
            { path: "startup", element: <LoadComponent component={Startup} /> },
            {
              path: "software",
              element: <LoadComponent component={Software} />,
            },

            { path: "agency", element: <LoadComponent component={Agency} /> },
            {
              path: "coworking",
              element: <LoadComponent component={Coworking} />,
            },
            { path: "crypto", element: <LoadComponent component={Crypto} /> },
            {
              path: "marketing",
              element: <LoadComponent component={Marketing} />,
            },
            {
              path: "portfolio",
              element: <LoadComponent component={Portfolio} />,
            },
          ],
        },
        {
          path: "pages",
          children: [
            {
              path: "blog",
              children: [
                { path: "", element: <LoadComponent component={Blog} /> },
                {
                  path: "post",
                  element: <LoadComponent component={BlogPost} />,
                },
              ],
            },
            { path: "company", element: <LoadComponent component={Company} /> },
            { path: "contact", element: <LoadComponent component={Contact} /> },
            { path: "career", element: <LoadComponent component={Career} /> },
            { path: "pricing", element: <LoadComponent component={Pricing} /> },
            {
              path: "portfolio",
              children: [
                {
                  path: "grid",
                  element: <LoadComponent component={PortfolioGrid} />,
                },
                {
                  path: "masonry",
                  element: <LoadComponent component={PortfolioMasonry} />,
                },
                {
                  path: "item",
                  element: <LoadComponent component={PortfolioItem} />,
                },
              ],
            },
            { path: "help", element: <LoadComponent component={HelpDesk} /> },
          ],
        },
      ],
    },
    {
      // protected routes
      path: "/",
      element: <PrivateRoute roles={"super-user"} />,
      children: [
        {
          path: "dashboard",
          children: [
            { path: "", element: <LoadComponent component={Dashboard} /> },

            //organisation
            {
              path: "organisations",
              element: <LoadComponent component={Organisations} />,
            },
            {
              path: ":organisationId/projects",
              element: <LoadComponent component={Projects} />,
            },
            {
              path: ":organisationId/organisation",
              element: <LoadComponent component={OrganisationDetail} />,
            },

            //wallets WalletStatement TransactionWidget
            {
              path: ":organisationId/wallet",
              element: <LoadComponent component={Wallet} />,
            },
            {
              path: ":organisationId/wallet-statement",
              element: <LoadComponent component={WalletStatement} />,
            },
            {
              path: ":organisationId/transaction-statement",
              element: <LoadComponent component={TransactionWidget} />,
            },

            //services
            {
              path: ":serviceId/:serviceType/service",
              element: <LoadComponent component={ServicePricingDetail} />,
            },
            {
              path: "2/vas/service",
              element: <LoadComponent component={BulkServicePricingDetail} />,
            },
            {
              path: "1/vas/service",
              element: <LoadComponent component={TransactionWidget} />,
            },
            {
              path: ":serviceId/:serviceType/detail",
              element: <LoadComponent component={ServiceDetail} />,
            },

            //pricing
            {
              path: ":serviceId/:serviceType/pricing",
              element: <LoadComponent component={PriceDetailWidget} />,
            },
            {
              path: ":serviceId/:serviceType/detail",
              element: <LoadComponent component={PriceDetail} />,
            },

            // SYSTEM SERVICE PARAMETERS
            
            {
              path: ":organisationId/system-configurations",
              element: <LoadComponent component={SystemServiceConfigurationsWidget} />,
            },


          ],
        },

        { path: "settings", element: <LoadComponent component={Settings} /> },
      ],
    },
  ]);
};

export default AllRoutes;
