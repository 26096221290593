import { APICore } from "./apiCore";

const api = new APICore();

const backendCustomerChannelsBaseUrl = process.env.REACT_APP_API_CUSTOMER_CHANNELS_URL;


function getTransactionByTransactionRefAPI(params: {
  transactionRef: string;
}) {
  const baseUrl = backendCustomerChannelsBaseUrl + "/fulfillment/transactions/history" ;
  const payload ={  
    "operation":"get_customer_transactions_by_reference",
   "requestBody": { 
    "reference": params.transactionRef
    }
}
  return api.createAuthOnly(`${baseUrl}`, payload); 
}

function verifyTransactionByTransactionRefAPI(params: {
  transactionRef: string;
}) {
  const baseUrl = backendCustomerChannelsBaseUrl + "/fulfillment/transactions/verify" ;
  const payload ={  
    "operation":"get_customer_transactions_by_reference",
   "requestBody": { 
    "reference": params.transactionRef
    }
}
  return api.createAuthOnly(`${baseUrl}`, payload); 
}

// ORGANISATION APIS

export { getTransactionByTransactionRefAPI ,verifyTransactionByTransactionRefAPI}; 